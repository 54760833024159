let burger = document.querySelector('.js-burger')
let panel = document.querySelector('.js-panel')
let panelBackdrop = document.querySelector('.js-panel-backdrop')
let burgerClose = document.querySelector('.js-panel__close')
const noScroll = document.body

if (burger) {
  burger.addEventListener('click', function () {
    panel.classList.add('is-active')
    panelBackdrop.classList.add('is-active')
    noScroll.classList.add('is-overflow')
  })
}

if (burgerClose) {
  burgerClose.addEventListener('click', function () {
    panel.classList.remove('is-active')
    panelBackdrop.classList.remove('is-active')
    noScroll.classList.remove('is-overflow')
  })
}

if (panelBackdrop) {
  panelBackdrop.addEventListener('click', function (e) {
    if (e.target.className === panelBackdrop.className) {
      panel.classList.remove('is-active')
      panelBackdrop.classList.remove('is-active')
      noScroll.classList.remove('is-overflow')
    }
  })
}
